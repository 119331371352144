import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import axios from 'axios';
import STATUSES from '../Statuses';
import config from '../../config';

const serviceURL = config.SERVICE_TRACEABILITY_URL;






// =============================================== Check List APIS ============================================
const CheckListSlice = createSlice({
    name : "CheckList",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetCheckListState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            isAnyOf(
                saveCheckListData.pending , 
                updateCheckListData.pending , 
                deleteCheckListData.pending , 
                saveUseCheckListData.pending , 
                updateUseCheckListData.pending , 
                deleteUseCheckListData.pending , 
                saveAssignCheckList.pending , 
                saveFavoriteCheckList.pending ,

                saveSOPData.pending , 
                updateSOPData.pending ,
                deleteSOPData.pending , 
                saveUseSOPData.pending,
                updateUseSOPData.pending,
                deleteUseSOPData.pending,
                saveAssignSOP.pending , 
                saveFavoriteSOP.pending,

                saveManualData.pending,
                updateManualData.pending,
                deleteManualData.pending,
                saveUseManualData.pending,
                updateUseManualData.pending,
                deleteUseManualData.pending,
                saveAssignManual.pending,
                saveFavoriteManual.pending,
            ),
            (state) => {
                state.status = STATUSES.LOADING;
            }
        );

        builder.addMatcher(
            isAnyOf(
                saveCheckListData.fulfilled, 
                updateCheckListData.fulfilled , 
                deleteCheckListData.fulfilled , 
                saveUseCheckListData.fulfilled, 
                updateUseCheckListData.fulfilled , 
                deleteUseCheckListData.fulfilled , 
                saveAssignCheckList.fulfilled , 
                saveFavoriteCheckList.fulfilled ,

                saveSOPData.fulfilled , 
                updateSOPData.fulfilled , 
                deleteSOPData.fulfilled , 
                saveUseSOPData.fulfilled,
                deleteUseSOPData.fulfilled,
                updateUseSOPData.fulfilled,
                saveAssignSOP.fulfilled , 
                saveFavoriteSOP.fulfilled,

                saveManualData.fulfilled,
                updateManualData.fulfilled,
                deleteManualData.fulfilled,
                saveUseManualData.fulfilled,
                updateUseManualData.fulfilled,
                deleteUseManualData.fulfilled,
                saveAssignManual.fulfilled,
                saveFavoriteManual.fulfilled,
            ),
            (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.SUCCESS;
                state.error = null;
            }
        );

        builder.addMatcher(
            isAnyOf(
                saveCheckListData.rejected , 
                updateCheckListData.rejected , 
                deleteCheckListData.rejected , 
                saveUseCheckListData.rejected , 
                updateUseCheckListData.rejected , 
                deleteUseCheckListData.rejected , 
                saveAssignCheckList.rejected , 
                saveFavoriteCheckList.rejected , 

                saveSOPData.rejected , 
                updateSOPData.rejected , 
                deleteSOPData.rejected ,
                saveUseSOPData.rejected, 
                deleteUseSOPData.rejected,
                updateUseSOPData.rejected,
                saveAssignSOP.rejected , 
                saveFavoriteSOP.rejected,

                saveManualData.rejected,
                updateManualData.rejected,
                deleteManualData.rejected,
                saveUseManualData.rejected,
                updateUseManualData.rejected,
                deleteUseManualData.rejected,
                saveAssignManual.rejected,
                saveFavoriteManual.rejected,
            ),
            (state, action) => {
                state.error = action.payload;
                state.status = STATUSES.ERROR;
            }
        );
    },
}); 

//  =========================================  CheckList APIS ============================================


// get data from CheckList
export const getCheckListData = createAsyncThunk( "checkList/get", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { page , size , sortBy , sortOrder , filters } = data;
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: { page, size, sortBy, sortOrder, filters },
        };

        const responce = await axios.get(`${serviceURL}/checklists/getAllChecklists`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// save Data in CheckList
export const saveCheckListData = createAsyncThunk( "checkList/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.post(`${serviceURL}/checklists/createChecklist`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// update Data in CheckList
export const updateCheckListData = createAsyncThunk( "checkList/update", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { id , ...restData } = data;
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.put(`${serviceURL}/checklists/${id}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// delete data from CheckList
export const deleteCheckListData = createAsyncThunk( "checkList/delete", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.delete(`${serviceURL}/checklists/${data.id}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})






// =============================================== USE Check List APIS ============================================


// get data from  useCheckList
export const getUseCheckListData = createAsyncThunk( "useCheckList/get", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.get(`${serviceURL}/useOfChecklists/${data.id}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// save Data in CheckList
export const saveUseCheckListData = createAsyncThunk( "useCheckList/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { checklistId , userName , ...restData} = data;
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params : {checklistId : checklistId , username : userName}
        };

        const responce = await axios.post(`${serviceURL}/useOfChecklists/useChecklist`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// update Data in CheckList
export const updateUseCheckListData = createAsyncThunk( "useCheckList/update", async ( data, { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.post(`${serviceURL}/useOfChecklists/${data.userId}`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// delete data from CheckList
export const deleteUseCheckListData = createAsyncThunk( "useCheckList/delete", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.delete(`${serviceURL}/useOfChecklists/${data.userId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})




// ================================================ Assign and Favorite checklist API =============================================

// Save Assign CheckList Data
export const saveAssignCheckList = createAsyncThunk( "assignCheckList/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { myUserId , ...restData } = data;

        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json"}};

        const responce = await axios.post(`${serviceURL}/useOfChecklists/assign`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// Save Favorite CheckList Data
export const saveFavoriteCheckList = createAsyncThunk( "FavoriteCheckList/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { isRemove , ...restData } = data;

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json"},
            params : { isRemove :isRemove }
        };

        const responce = await axios.post(`${serviceURL}/useOfChecklists/markAsFavChecklist`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// get data from  useCheckList
export const getFavAndAssignedCheckList = createAsyncThunk( "favAndAssignedCheckList/get", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.get(`${serviceURL}/useOfChecklists/getAllFavAndAssignedChecklists/${data.userId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

















//  =========================================  SOP APIS ==========================================================================================================================================================================================================================================================================================================================


// get data from SOP
export const getSOPData = createAsyncThunk( "SOP/get", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { page , size , sortBy , sortOrder , filters } = data;
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: { page, size, sortBy, sortOrder, filters },
        };

        const responce = await axios.get(`${serviceURL}/sops/getAllSOPs`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// save Data in SOP
export const saveSOPData = createAsyncThunk( "SOP/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.post(`${serviceURL}/sops/createSOP`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// update Data in SOP
export const updateSOPData = createAsyncThunk( "SOP/update", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { sopId , ...restData } = data;
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.put(`${serviceURL}/SOPs/${sopId}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// delete data from SOP
export const deleteSOPData = createAsyncThunk( "SOP/delete", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.delete(`${serviceURL}/sops/${data.sopId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})



// ================================ SOP Images and PDF ===================================

// upload sop images
export const uploadSOPImg = createAsyncThunk( "SOP/UploadImg", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = {headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", }};

        const responce = await axios.post(`${serviceURL}/sops/${data.sopId}/uploadSOPImage`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// upload sop PDF
export const uploadSOPPdf = createAsyncThunk( "SOP/UploadPdf", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = {headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", }};

        const responce = await axios.post(`${serviceURL}/sops/${data.sopId}/uploadSOPPdf`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// upload sop section image
export const uploadSOPSectionImg = createAsyncThunk( "SOPSection/UploadImg", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", }};

        const responce = await axios.post(`${serviceURL}/sops/${data.sopId}/sections/${data.sectionKey}/uploadSectionImage`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// upload sop section PDF
export const uploadSOPSectionPdf = createAsyncThunk( "SOPSection/UploadPDF", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", }};

        const responce = await axios.post(`${serviceURL}/sops/${data.sopId}/sections/${data.sectionKey}/uploadSectionPdf`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// upload sop task images
export const uploadSOPTaskImg = createAsyncThunk( "SOPTask/UploadImg", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = {headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", }};

        const responce = await axios.post(`${serviceURL}/sops/${data.sopId}/sections/${data.sectionKey}/tasks/${data.taskKey}/uploadTaskImage`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// upload sop Task PDF
export const uploadSOPTaskPdf = createAsyncThunk( "SOPTask/UploadPdf", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = {headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", }};

        const responce = await axios.post(`${serviceURL}/sops/${data.sopId}/sections/${data.sectionKey}/tasks/${data.taskKey}/uploadTaskPdf`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})



// =============================================== USE SOP APIS ============================================


// get data from  useSOP
export const getUseSOPData = createAsyncThunk( "useSOP/get", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.get(`${serviceURL}/SOPs/useOfSOPs/${data.id}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// save Data in SOP
export const saveUseSOPData = createAsyncThunk( "useSOP/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { sopId , userName , ...restData} = data;
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params : {sopId : sopId , username : userName}
        };

        const responce = await axios.post(`${serviceURL}/useOfSOPs/useSOP`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Data in SOP
export const updateUseSOPData = createAsyncThunk( "useSOP/update", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { sopId , ...restData} = data;

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.put(`${serviceURL}/useOfSOPs/${sopId}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// delete data from SOP
export const deleteUseSOPData = createAsyncThunk( "useSOP/delete", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.delete(`${serviceURL}/useOfSOPs/${data.sopId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// ================================================ Assign and Favorite SOP API =============================================

// Save Assign SOP Data
export const saveAssignSOP = createAsyncThunk( "assignSOP/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { myUserId , ...restData } = data;

        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json"}};

        const responce = await axios.post(`${serviceURL}/useOfSOPs/assign`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// Save Favorite SOP Data
export const saveFavoriteSOP = createAsyncThunk( "FavoriteSOP/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { isRemove , ...restData } = data;

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json"},
            params : { isRemove :isRemove }
        };

        const responce = await axios.post(`${serviceURL}/useOfSOPs/markAsFavSOP`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// get data from  useSOP
export const getFavAndAssignedSOP = createAsyncThunk( "favAndAssignedSOP/get", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.get(`${serviceURL}/useOfSOPs/getAllFavAndAssignedSOPs/${data.userId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})














//  =========================================  manual APIS ==========================================================================================================================================================================================================================================================================================================================

// get data from Manual
export const getManualData = createAsyncThunk( "Manual/get", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { page , size , sortBy , sortOrder , filters } = data;
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: { page, size, sortBy, sortOrder, filters },
        };

        const responce = await axios.get(`${serviceURL}/manuals/getAllManuals`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// save Data in Manual
export const saveManualData = createAsyncThunk( "Manual/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.post(`${serviceURL}/manuals/createManual`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Data in Manual
export const updateManualData = createAsyncThunk( "Manual/update", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { manualId , ...restData } = data;
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.put(`${serviceURL}/manuals/${manualId}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// delete data from Manual
export const deleteManualData = createAsyncThunk( "Manual/delete", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.delete(`${serviceURL}/manuals/${data.manualId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


//================================== Manual PDF============================ 


// upload Manual PDF
export const uploadManualPdf = createAsyncThunk( "Manual/UploadPdf", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = {headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", }};

        const responce = await axios.post(`${serviceURL}/manuals/${data.manualId}/uploadManual`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// =============================================== USE Manual APIS ============================================


// get data from  useManual
export const getUseManualData = createAsyncThunk( "useManual/get", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.get(`${serviceURL}/Manuals/useOfManuals/${data.id}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// save Data in Manual
export const saveUseManualData = createAsyncThunk( "useManual/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { manualId , userName , ...restData} = data;
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params : {manualId : manualId , username : userName}
        };

        const responce = await axios.post(`${serviceURL}/useOfManuals/useManual`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Data in Manual
export const updateUseManualData = createAsyncThunk( "useManual/update", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { manualId , ...restData} = data;

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.put(`${serviceURL}/useOfManuals/${manualId}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// delete data from Manual
export const deleteUseManualData = createAsyncThunk( "useManual/delete", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.delete(`${serviceURL}/useOfManuals/${data.manualId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// ================================================ Assign and Favorite Manual API =============================================

// Save Assign Manual Data
export const saveAssignManual = createAsyncThunk( "assignManual/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { myUserId , ...restData } = data;

        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json"}};

        const responce = await axios.post(`${serviceURL}/useOfManuals/assign`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// Save Favorite Manual Data
export const saveFavoriteManual = createAsyncThunk( "FavoriteManual/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { isRemove , ...restData } = data;

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json"},
            params : { isRemove :isRemove }
        };

        const responce = await axios.post(`${serviceURL}/useOfManuals/markAsFavManual`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// get data from  useManual
export const getFavAndAssignedManual = createAsyncThunk( "favAndAssignedManual/get", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.get(`${serviceURL}/useOfManuals/getAllFavAndAssignedManuals/${data.userId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})










export {
    CheckListSlice,
}

export const {resetCheckListState} = CheckListSlice.actions;



